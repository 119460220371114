@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Nunito", "Roboto", sans-serif;
  font-weight: 800;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 6px;               /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background:lightblue;        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: gray;    /* color of the scroll thumb */
  border-radius: 3px;       /* roundness of the scroll thumb */
  border: 1px solid #f6f7f8;  /* creates padding around scroll thumb */
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

h2,label,p, span{
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

h4{
  font-family: "Roboto", sans-serif;
  padding: 0.5rem 0;
  font-weight: 700;
}

input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

input[type="number"]{
    -moz-appearance: textfield;
}

.pyramid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layer {
  cursor: pointer;
}

.pyramid-svg{
  transform:translate(6rem,-2rem);
  border: none;
  width: 220px;
  height: 220px;
}

.pyramid-svg text {
  pointer-events: none;
}


@layer base{
  h2{
    @apply text-xl font-bold text-gray-800 mt-3 py-3;
  }
  .profileDiv{
    @apply flex flex-row items-center pt-1;
  }
  label{
    @apply inline-block font-thin mr-0 ml-12 my-0 px-0 py-1 overflow-hidden w-24;
  }
  input,select,option{
    @apply inline-block font-thin border border-gray-300 ml-0 mr-6 my-0 px-2 py-0 overflow-hidden;
  }
  .wider-input{
    @apply w-48;
  }
  .shorter-input{
    @apply w-24;
  }
  .required{
    @apply text-red-500;
  }
}
